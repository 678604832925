
import YfnSelect from "../../../components/YfnSelect.vue";
export default {
  name: "productCustom",
  components: { YfnSelect },
  asyncData({ api, cookies, ...context }) {},
  props: {
    customData: Object,
    middleEmit: Object,
  },
  data() {
    return {
      showPicker: false,
      fontList() {
        return {
          defaultFont: this.customData[0].defaultFont || "",
          optionList: this.customData[0]?.fontList || [],
          isShow: false,
          optionType: 3,
        };
      },
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    // console.log(this.customData);
  },
  methods: {
    addCustom() {
      let isReq = true;
      this.customData?.map((item) => {
        let value = item.defaultValue || item.defaultText || item.defaultValue;
        if (item.isRequired && !value) {
          item.isError = true;
          isReq = false;
        } else {
          item.isError = false;
        }
      });
      this.$forceUpdate();
      if (isReq) {
        let targetWindow = document.getElementById("prodSub").contentWindow;
        targetWindow?.postMessage({
          type: "customAdd",
          data: {}, //发送数据,
        });
        setTimeout(() => {
          this.middleEmit.fn("closeLoad");
        }, 4000);
      } else {
        this.$VueScrollTo.scrollTo("#custom-ul", "", { offset: -150 });
        this.middleEmit.fn("closeLoad");
      }
    },
    fontChange(item) {
      this.customData.forEach((res, index) => {
        if (res.serviceType == 1 && res.defaultFont != item) {
          res.defaultFont = item;
          this.customUpdate({
            color: res.defaultColor,
            family: res.defaultFont,
            text: res.defaultText,
            serviceType: res.serviceType,
            index: index,
          });
        }
      });
    },
    inputBlur(item, index) {
      if (!item.defaultText && item.isRequired) {
        item.isError = true;
      } else {
        item.isError = false;
      }
      this.$forceUpdate();
      this.customUpdate({
        color: item.defaultColor,
        family: item.defaultFont || "Arial",
        text: item.defaultText,
        serviceType: item.serviceType,
        index: index,
      });
    },
    selectChange(item) {
      if (item.optionType == "image") {
        //图片选项
        this.customUpdate({
          value: `${item.optionName}(${this.$price(item.displayCustomizeFee)})`,
          customizeFee: item.customizeFee,
          displayCustomizeFee: item.displayCustomizeFee,
          optionUrl: item.optionValue,
          title: item.optionName,
          sign: item.sign,
          optionType: item.optionType,
          serviceType: item.serviceType,
          index: item.index,
        });
      } else {
        //文字选项
        this.customUpdate({
          value: `${item.optionValue}(${this.$price(
            item.displayCustomizeFee
          )})`,
          text: `${item.optionValue}(${this.$price(item.displayCustomizeFee)})`,
          title: item.optionValue,
          customizeFee: item.customizeFee,
          displayCustomizeFee: item.displayCustomizeFee,
          sign: item.sign,
          optionType: item.optionType,
          serviceType: item.serviceType,
          index: item.index,
        });
      }
      this.customData[item.index].isError = false;
      this.$forceUpdate();
    },
    customUpdate(data) {
      this.middleEmit.fn('setCustomFree')  //定制数据传递
      let targetWindow = document.getElementById("prodSub").contentWindow;
      targetWindow?.postMessage({
        type: "prodUpdate",
        data: data, //发送数据,
      });
    },
  },
};

import { render, staticRenderFns } from "./productCustom.vue?vue&type=template&id=56adee24&scoped=true&"
import script from "./productCustom.vue?vue&type=script&lang=js&"
export * from "./productCustom.vue?vue&type=script&lang=js&"
import style0 from "./productCustom.vue?vue&type=style&index=0&id=56adee24&prod&scoped=true&lang=scss&"
import style1 from "./productCustom.vue?vue&type=style&index=1&id=56adee24&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56adee24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnSelect: require('/home/php1/m-nuxt/components/YfnSelect.vue').default})
